.ag-row .ag-cell {
  display: inline-flex;
  align-items: center;
}
.ag-cell {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  padding-right: 2px;
  align-items: left;
  white-space: normal !important;
}

@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin.scss';
@import '../../assets/styles/ag-grid-custom.css';

.ag-theme-material {
  @include ag-theme-material();
}
.ag-theme-material-dark {
  $background: #171717;
  $foreground: #f5f5f5;

  @include ag-theme-material(
    (
      background-color: $background,
      foreground-color: $foreground,
      secondary-foreground-color: $foreground,
      header-background-color: $background,
      header-foreground-color: $foreground,
      header-cell-hover-background-color: darken($background, 5),
      header-cell-moving-background-color: darken($background, 5),
      border-color: #424242,
      row-hover-color: #0061e266,
    )
  );
}
